document.addEventListener("DOMContentLoaded", function () {
    console.debug("INFO campaign.js loaded");
    //set affiliate data
    setAffiliateDataCookieTracking();

    // cookie Names
    var traci_session = "traci_session";
    var traci_a_cookie = "traci_a";
    var traci_p_cookie = "traci_p";

    const params_to_track = ["ewbs", "apid"];

    // DataLayer Parameter Variable
    tracking.params = {};

    // getting all Campaign Tracking Parameters
    var trackingParams = getTrackingUrlParams(location.href);
    if (trackingParams != null && Object.keys(trackingParams).length != 0) {
        var sessionCookieValue = getTraci_SessionParams(trackingParams);
        if (sessionCookieValue.constructor === Object && Object.keys(sessionCookieValue).length != 0) {
            var cookieValue = setCookieValue(traci_session, sessionCookieValue);
            setCookie(traci_session, cookieValue);
        }
        var traci_a_cookieValue = getTraci_a_cookieParams(trackingParams);
        if (traci_a_cookieValue.constructor === Object && Object.keys(traci_a_cookieValue).length != 0) {
            var cookieValue = setCookieValue(traci_a_cookie, traci_a_cookieValue);
            setCookie(traci_a_cookie, cookieValue, (90 * 24 * 3600));
        }

        var traci_p_cookieValue = getTraci_p_cookieParams(trackingParams);
        if (traci_p_cookieValue.constructor === Object && Object.keys(traci_p_cookieValue).length != 0) {
            var cookieValue = setCookieValue(traci_p_cookie, traci_p_cookieValue);
            setCookie(traci_p_cookie, cookieValue, (365 * 24 * 3600));
        }
        // remove all Tracking Parametes from Url
        var newUrl = removeURLParameter(location.href, trackingParams);
        // Browser History will be overwritten with updated URL
        refreshURL(newUrl);
    } else {
        console.debug("Error: campaign.js: trackingParams is null. ");
    }

    var sessionCookie = getCookie(traci_session);
    if (sessionCookie) {
        setDataLayerFromCookie(JSON.parse(sessionCookie))
    }
    var a_cookie = getCookie(traci_a_cookie);
    if (a_cookie) {
        setDataLayerFromCookie(JSON.parse(a_cookie))
    }
    var p_cookie = getCookie(traci_p_cookie);
    if (p_cookie) {
        setDataLayerFromCookie(JSON.parse(p_cookie))
    }

    // Trigger event on document for other functionality to access tracking params
    triggerEvent(document, "trackingParamsSet");


    function setCookieValue(cookiename, parameters) {
        var cookieValue = "";
        var cookie = getCookie(cookiename);
        if (cookie) {
            var cookieObj = JSON.parse(cookie);
            for (var key in parameters) {
                cookieObj[key] = parameters[key];
            }
            cookieValue = JSON.stringify(cookieObj);
        } else {
            cookieValue = JSON.stringify(parameters);
        }
        return cookieValue;
    }


    function getTrackingUrlParams(url) {
        // get query string from url (optional) or window
        var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
        // we'll store the parameters here
        var obj = {};
        // if query string exists
        if (queryString) {
            // stuff after # is not part of query string, so get rid of it
            queryString = queryString.split('#')[0];
            // split our query string into its component parts
            var arr = queryString.split('&');
            for (var i = 0; i < arr.length; i++) {
                // separate the keys and the values
                var a = arr[i].split('=');
                // set parameter name and value (use 'true' if empty)
                var paramName = a[0];
                var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
                if (params_to_track.includes(paramName)) {
                    // we're dealing with a string
                    obj[paramName] = paramValue;
                }
            }
        }
        return obj;
    }


    function removeURLParameter(url, parameters) {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {
            for (var key in parameters) {
                var value = parameters[key];
                var removeVal = key + "=" + value;
                if (url.indexOf('?' + removeVal + '&') != "-1") {
                    url = url.replace('?' + removeVal + '&', '?');
                } else if (url.indexOf('&' + removeVal + '&') != "-1") {
                    url = url.replace('&' + removeVal + '&', '&');
                } else if (url.indexOf('?' + removeVal) != "-1") {
                    url = url.replace('?' + removeVal, '');
                } else if (url.indexOf('&' + removeVal) != "-1") {
                    url = url.replace('&' + removeVal, '');
                }
            }
        }
        return url;
    }

    function refreshURL(newUrl) {
        // Browserhistorie wird mit URL ohne Parameter überschrieben
        window.history.replaceState({}, '', newUrl)
    };


    function getTraci_SessionParams(allParams) {
        var sessionParams = {};
        var params_to_track = ["ewbs"];
        for (var i = 0; i < params_to_track.length; i++) {
            if (allParams.hasOwnProperty(params_to_track[i])) {
                sessionParams[params_to_track[i]] = allParams[params_to_track[i]];
            }
        }
        return sessionParams;
    }

    function getTraci_a_cookieParams(allParams) {
        var cookieParams = {};
        var params_to_track = ["apid"];
        for (var i = 0; i < params_to_track.length; i++) {
            if (allParams.hasOwnProperty(params_to_track[i])) {
                cookieParams[params_to_track[i]] = allParams[params_to_track[i]];
            }
        }
        return cookieParams;
    }


    function getTraci_p_cookieParams(allParams) {
        var cookieParams = {};
        var params_to_track = ["traci_p"];
        for (var i = 0; i < params_to_track.length; i++) {
            if (allParams.hasOwnProperty(params_to_track[i])) {
                cookieParams[params_to_track[i]] = allParams[params_to_track[i]];
            }
        }
        return cookieParams;
    }

    function setCookie(variable, value, expires_seconds) {
        if (expires_seconds) {
            var d = new Date();
            d = new Date(d.getTime() + 1000 * expires_seconds);
            document.cookie = variable + '=' + value + '; path=/; expires=' + d.toGMTString() + '; Secure; SameSite=None';
        } else {
            document.cookie = variable + '=' + value + '; path=/; Secure; SameSite=None';
        }
    }

    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    function setDataLayerFromCookie(cookieParams) {
        for (var key in cookieParams) {
            tracking.params[key] = encodeURIComponent(cookieParams[key]);
        }
    }

    function triggerEvent(el, eventName, options) {
        var event;
        if (window.CustomEvent) {
            event = new CustomEvent(eventName, options);
        } else {
            event = document.createEvent('CustomEvent');
            event.initCustomEvent(eventName, true, true, options);
        }
        el.dispatchEvent(event);
    }

    //----------------------------------
    //ADD CODE FOR AFFILIATE DATA COOKIE
    //----------------------------------
    function setAffiliateDataCookieTracking() {
        var COOKIE_NAME = "affiliatedata";
        var COOKIE_EXPIRATION_DAYS = 30;
        var APID_REGEX = /^[a-zA-Z0-9_-]*$/;
        var EWBS_REGEX = /^[0-9]{6}$/;

        var ewbs = VWFSUtils.getURLParameter("ewbs");
        var apid = VWFSUtils.getURLParameter("apid");
        if (ewbs) {
            // cookie creation on current domain
            setAffiliateData(ewbs, apid);
        }

        function setAffiliateData(ewbs, apid) {
            if (EWBS_REGEX.test(ewbs) && (!apid || APID_REGEX.test(apid))) {
                setAffiliateDataCookie(COOKIE_NAME, composeCookieValue(ewbs, apid), COOKIE_EXPIRATION_DAYS);
                return true;
            } else {
                return false;
            }
        }

        //always (!) call adform-include.js, even if no EWBS is set
        triggerEvent(document, "executeAdForm");

        /**
         * compose cookie value
         * @param ewbs
         * @param apid
         * @returns {string}
         */
        function composeCookieValue(ewbs, apid) {
            return "ewbs:" + ewbs + ",apid:" + (apid ? apid : "");
        }

        /**
         * get cookie domain
         * @returns {string}
         */
        function getCookieDomain() {
            var sld = getRootDomain();
            return sld === "vwfs.tools" ? window.location.host : sld;
        }

        /**
         * get root domain (second level domain) from location
         * @returns {string}
         */
        function getRootDomain() {
            var domain = window.location.host.split(":")[0].split(".");
            if (domain.length <= 1) {
                return domain.join(".");
            } else {
                var sec = domain.length > 2 ? domain[domain.length - 2] : null;
                return sec === "co" || sec === "com" || sec === "net" || sec === "org" ? domain.slice(-3).join(".") : domain.slice(-2).join(".");
            }
        }

        /**
         * set the cookie
         * @param value
         * @param expirationDays
         * @returns {boolean}
         */
        function setAffiliateDataCookie(cookieName, value, expirationDays) {
            var expires = "";
            if (expirationDays) {
                var date = new Date();
                date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            var domain = getCookieDomain();
            document.cookie = encodeURIComponent(cookieName) + "=" + encodeURIComponent(value)
                + expires
                + (domain !== "localhost" ? "; domain=" + domain : "")
                + "; path=/; Secure; SameSite=None";
            return true;
        }
    }

    //----------------------------------
    //END FOR AFFILIATE DATA COOKIE
    //----------------------------------
});
